import modules from './modules'

const urls = {
    home: '/',
    login: '/login',
    userCallback: '/user/callback',
    facility: '/facility',
    facilities: '/facilities',
    bidPackage: '/bidPackage',
    bidPackages: '/bidPackages',
    bidResponse: '/bidResponse',
    bidResponses: '/bidResponses',
    invoice: '/invoice',
    invoices: '/invoices',
    projects: '/projects',
    project: '/project',
    entity: '/entity',
    entities: '/entities',
    well: '/well',
    wells: '/wells',
    bond: '/bond',
    bonds: '/bonds',
    features: [
        {
            name: 'Facilities',
            url: '/facilities',
            type: modules.plugging.type
        },
        {
            name: 'Bid Packages',
            url: '/bidPackages',
            type: modules.plugging.type
        },
        {
            name: 'Proposal Responses',
            url: '/bidResponses',
            type: modules.plugging.type
        },
        {
            name: 'Invoices',
            url: '/invoices',
            type: modules.plugging.type
        },
        {
            name: 'Wells',
            url: '/wells',
            type: modules.editor.type
        },
        {
            name: 'Entities',
            url: '/entities',
            type: modules.editor.type
        },
        {
            name: 'Bonds',
            url: '/bonds',
            type: modules.editor.type
        }
    ]
}

const apiUrls = {
    facility: 'facility',
    facilityGroup: 'facilityGroup',
    bidPackage: 'bidPackage',
    bidResponse: 'bidResponse',
    invoice: 'invoice',
    schema: 'schema',
    lookup: 'lookup',
    autoCalc: 'autoCalculate',
    upload: 'upload',
    entity: 'entity',
    bond: 'bond',
    well: 'well'
}

const getCreateLink = (url) => {
    return `${url}/new`
}

export { apiUrls, getCreateLink }
export default urls
