import RestrictedAccess from './RestrictedAccess'

const ViewerAccess = ({ children }) => {
    return (
        <RestrictedAccess
            allowedRoles={['Admin', 'Editor', 'Viewer']}
        >
            {children}
        </RestrictedAccess>
    )
}

export default ViewerAccess