import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Header from './components/elem/Header'

import UserContextProvider from './components/wrappers/UserContext'
import APIRequestContextProvider from './components/wrappers/APIRequestContext'

import urls from './utils/constants/urls'

import GlobalStyle from './theme/GlobalStyle'
import FormStateContextProvider, { FormStateContext } from './components/wrappers/FormStateContext'
import AsyncPage from './components/wrappers/AsyncPage'
import routePaths from './utils/constants/routePaths'
import EditorAccess from './components/features/user/EditorAccess'
import ViewerAccess from './components/features/user/ViewerAccess'

function App() {
    return (
        <React.Fragment>
            <GlobalStyle />
            <Header />
            <div className="contentWrapper">
                <Router>
                    <UserContextProvider>
                        <APIRequestContextProvider>
                            <FormStateContextProvider>
                                <Routes>
                                    <Route
                                        exact
                                        path={urls.userCallback}
                                        element={<AsyncPage page={routePaths.callback} />}
                                    />
                                    <Route
                                        exact
                                        path="/"
                                        element={
                                            <ViewerAccess>
                                                <AsyncPage page={routePaths.landing} />
                                            </ViewerAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/modules/:moduleName"
                                        element={
                                            <ViewerAccess>
                                                <AsyncPage page={routePaths.modules} />
                                            </ViewerAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/facilities"
                                        element={<AsyncPage page={routePaths.facilities} />}
                                    />
                                    <Route
                                        exact
                                        path="/bidPackages"
                                        element={
                                            <AsyncPage page={routePaths.bidPackages} />
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/bidResponses"
                                        element={
                                            <AsyncPage page={routePaths.bidResponses} />
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/invoices"
                                        element={
                                            <AsyncPage page={routePaths.invoices} />
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/entities"
                                        element={
                                            <AsyncPage page={routePaths.entities} />
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/bonds"
                                        element={
                                            <AsyncPage page={routePaths.bonds} />
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/wells"
                                        element={
                                            <AsyncPage page={routePaths.wells} />
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/facility/new'}
                                        element={
                                            <EditorAccess>
                                                <AsyncPage page={routePaths.facility} />
                                            </EditorAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/bidPackage/new'}
                                        element={
                                            <EditorAccess>
                                                <AsyncPage page={routePaths.bidPackage} />
                                            </EditorAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/bidResponse/new'}
                                        element={
                                            <EditorAccess>
                                                <AsyncPage page={routePaths.bidResponse} />
                                            </EditorAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/invoice/new'}
                                        element={
                                           <EditorAccess>
                                                <AsyncPage page={routePaths.invoice} />
                                            </EditorAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/entity/new'}
                                        element={
                                            <EditorAccess>
                                                <AsyncPage page={routePaths.entity} />
                                            </EditorAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/bond/new'}
                                        element={
                                           <EditorAccess>
                                                <AsyncPage page={routePaths.bond} />
                                            </EditorAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/well/new'}
                                        element={
                                           <EditorAccess>
                                                <AsyncPage page={routePaths.well} />
                                            </EditorAccess>
                                    }
                                    />
                                    <Route
                                        exact
                                        path={'/facility/:facilityId'}
                                        element={
                                            <ViewerAccess>
                                                <AsyncPage page={routePaths.facility} />
                                            </ViewerAccess>
                                        }
                                    />
                                    
                                    <Route
                                        exact
                                        path={'/bidPackage/:bidPackageId'}
                                        element={
                                            <ViewerAccess>
                                                <AsyncPage page={routePaths.bidPackage} />
                                            </ViewerAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/bidResponse/:bidResponseId'}
                                        element={
                                            <ViewerAccess>
                                                <AsyncPage page={routePaths.bidResponse} />
                                            </ViewerAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/invoice/:invoiceId'}
                                        element={
                                            <ViewerAccess>
                                                <AsyncPage page={routePaths.invoice} />
                                            </ViewerAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/entity/:entityId'}
                                        element={
                                            <ViewerAccess>
                                                <AsyncPage page={routePaths.entity} />
                                            </ViewerAccess>
                                        }
                                    />  
                                    <Route
                                        exact
                                        path={'/bond/:bondId'}
                                        element={
                                            <ViewerAccess>
                                                <AsyncPage page={routePaths.bond} />
                                            </ViewerAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/well/:facilityId'}
                                        element={
                                            <ViewerAccess>
                                                <AsyncPage page={routePaths.well} />
                                            </ViewerAccess>
                                        }
                                    />
                                    <Route path="/login" element={<AsyncPage page={routePaths.login} />} />
                                </Routes>
                            </FormStateContextProvider>
                        </APIRequestContextProvider>
                    </UserContextProvider>
                </Router>
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

export default App
