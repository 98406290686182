import React, { createContext, useCallback, useContext } from 'react'
import { UserContext } from './UserContext'
import withConfig from './withConfig'
// import { useAuth } from 'oidc-react'

const defaultOptions = {
    cache: 'default',
    headers: {
        CacheControl: 'max-age=3600'
    }
}

const APIRequestContext = createContext(null)

const APIRequestContextProvider = withConfig(({ config, children }) => {
    const { client } = useContext(UserContext)
    const { USE_OKTA } = config
    const authenticatedFetch = useCallback(async (endpoint, options) => {
        let u = null
        if (USE_OKTA) {
            const tokens = await client.tokenManager.getTokens()
            if (tokens.accessToken) {
                u = { access_token: tokens.accessToken.accessToken }
            }
        } else {
            u = await client.getUser()
        }
        let fetchOptions = {}
        const authorizationHeader = u ? {
            Authorization: `Bearer ${u.access_token}`,
        } : {}
        if (options) {
            fetchOptions = {
                ...defaultOptions,
                ...options,
                headers: {
                    ...defaultOptions.headers,
                    ...options.headers,
                    ...authorizationHeader
                },
            }
        } else {
            fetchOptions = {
                method: 'GET',
                ...defaultOptions,
                headers: {
                    ...defaultOptions.headers,
                    ...authorizationHeader
                },
            }
        }
        return fetch(endpoint, fetchOptions)
    }, [client, USE_OKTA])

    return (
        <APIRequestContext.Provider
            value={{authenticatedFetch}}
        >
            {children}
        </APIRequestContext.Provider>
    )
})

export { APIRequestContext }
export default APIRequestContextProvider
