const modules = {
    editor: {
            name: 'Editor',
            url: '/modules/editor',
            type: 'editor'
    },
    plugging: {
        name: 'Plugging', 
        url: '/modules/plugging',
        type: 'plugging'
    }
}

export default modules