const getRoles = (user, USE_OKTA) => {
    if (USE_OKTA) {
        if (user && user.groups) {
            const roles = user.groups
            if (Array.isArray(roles)) {
                return roles.join(',')
            } else {
                return roles
            }
        }
    } else {
        if (user && user.profile && user.profile.role) {
            const roles = user.profile.role
            if (Array.isArray(roles)) {
                return roles.join(',')
            } else {
                return roles
            }
        } else {
            return ''
        }
    }
}

const checkRole = (role, roles) => {
    if (roles && role) {
        return roles.toLowerCase().includes(role.toLowerCase())
    } else {
        return false
    }
}

const hasAccessToAdmin = roles => checkRole('Admin', roles)
const hasAccessToDashboard = roles => checkRole('Dashboard', roles)

export {
    getRoles,
    checkRole,
    hasAccessToAdmin,
    hasAccessToDashboard
}
