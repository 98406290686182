import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom';

const FormStateContext = createContext(null)

const FormStateContextProvider = ({ children }) => {
	const location = useLocation();
	const pathname = useMemo(() => location.pathname.split('/').filter(x => x), [location])
	const [breadCrumbList, setBreadCrumbList] = useState([])

    const updateBreadCrumbList = useCallback((currentIdx) => {
		const pathTitle = pathname[0]
		const formId = pathname[1]

		let formTitle = ""
		switch(pathTitle) {
			case "bidPackage":
				formTitle = "Bid Package"
				break
			case "facility":
				formTitle = "Facility"
				break
			case "bidResponse":
				formTitle = "Proposal Response"
				break
			case "invoice":
				formTitle = "Invoice"
				break
			case "bond":
				formTitle = "Bond"
				break
			case "entity":
				formTitle = "Entity"
				break
			case "well":
				formTitle = "Well"
				break
			default:
				formTitle = "Unknown"
				break
		}
		let sessionStorageBread = window.sessionStorage.getItem("breadCrumbList")
		let newBreadCrumbList =
			breadCrumbList && breadCrumbList.length ? breadCrumbList 
			: sessionStorageBread && sessionStorageBread.length ? JSON.parse(sessionStorageBread)
			: [] // all this gibberish to ensure that newBreadCrumbList is never undefined 

		if(currentIdx === null || currentIdx === undefined) {
			if(newBreadCrumbList.map(x => x.path).indexOf(pathTitle) > -1// if the selected route is already in the breadcrumblist do not change the breadcrumbs, just redirect
				&& newBreadCrumbList.map(x => x.id).indexOf(formId) > -1
			) {
				setBreadCrumbList(newBreadCrumbList)
				return
			}
			newBreadCrumbList = [ ...newBreadCrumbList, { title: formTitle, id: formId, path: pathTitle } ]
			setBreadCrumbList(newBreadCrumbList)
			window.sessionStorage.setItem("breadCrumbList",  JSON.stringify(newBreadCrumbList)) // we save in session storage because we need it to persist on page refresh
			return
		}

		newBreadCrumbList.forEach((x, idx) => {
			if(currentIdx === idx) {
				newBreadCrumbList = newBreadCrumbList.slice(0, idx) 
				setBreadCrumbList(newBreadCrumbList)
				window.sessionStorage.setItem("breadCrumbList",  JSON.stringify(newBreadCrumbList)) // we save in session storage because we need it to persist on page refresh
				return
			}
		})
    }, [setBreadCrumbList, pathname, breadCrumbList])

	useEffect(() => {
		const breadCrumbRouteMap = [ // a collection of routes that are valid for breadcrumbing 
			"facility",
			"bidResponse",
			"bidPackage",
			"invoice",
			"entity",
			"well",
			"bond"
		]
		if (breadCrumbRouteMap.indexOf(pathname[0]) === -1) { //if the current path is not in the collection of routes that are valid for breadcrumbing don't initialize the breadcrumbs
			setBreadCrumbList([])
			window.sessionStorage.removeItem("breadCrumbList")
			return
		}

		updateBreadCrumbList()
	}, [pathname])

    return (
        <FormStateContext.Provider
            value={{
				breadCrumbList,
				updateBreadCrumbList,
			}}
        >
            {children}
        </FormStateContext.Provider>
    )
}

export { FormStateContext }
export default FormStateContextProvider
